<template>
  <div>
    <OrdenPagoForm
      v-if="this.$route.params.paymentGroupId != null"
    ></OrdenPagoForm>
    <v-card v-if="this.$route.params.paymentGroupId == null">
      <v-data-table
        :headers="headPg"
        :options.sync="options"
        :items="lista"
        :items-per-page="itemsPerPage"
        :loading="loadingTable"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <ExpandableFilters
            classButton="py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0"
            iconButtom="mdi-upload"
            classTitle="col-0"
            :filters="filters"
            @datafilters="cargarLista(true)"
          />
        </template>
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="red"
            target="_blank"
            :to="`/orden-pago/${item.payment_group_id}`"
          >
            <v-icon color="#ff9800">mdi-table-edit</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from "../general/ExpandableFilters";
import OrdenPagoForm from "../contabilidad/OrdenpagoForm.vue";
export default {
  name: "OrdenesPago",
  components: {
    ExpandableFilters,
    OrdenPagoForm,
  },
  data: () => ({
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    overlay: false,

    headPg: [
      { text: "Opciones", value: "opcion", align: "center", width: 50 },
      { text: "No.", value: "payment_group_id", align: "start" },
      { text: "Fecha", value: "fecha", align: "start" },
      { text: "Tipo", value: "description", align: "start" },
      { text: "Descripción", value: "payment_group_name", align: "start" },
      { text: "Estado", value: "estado", align: "end" },
      { text: "Solicitado", value: "total_solicitado", align: "end" },
      
    ],

    filters: [
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-1 pl-0 pl-sm-2",
        v_model: "",
        label: "Numero",
        type: "input_text",
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-3 pr-0 pr-sm-2 mb-1",
        v_model: [],
        label: "Fecha",
        type: "input_date_time",
        clearable: true,
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Tipo",
        type: "input_select",
        text: "tipoPagoNombre",
        value: "tipoPagoId",
        clearable: true,
        multiple: false,
        items: [
          { tipoPagoId: "BATCH_PAYMENT", tipoPagoNombre: "Pago a proveedor" },
          {
            tipoPagoId: "BATCH_ANTICIPO",
            tipoPagoNombre: "Anticipo a proveedor",
          },
          {
            tipoPagoId: "REEMBOLSO_CLIENTE",
            tipoPagoNombre: "Reembolso a cliente",
          },
        ],
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-1 pl-0 pl-sm-2",
        v_model: "",
        label: "Descripcion",
        type: "input_text",
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Estado",
        type: "input_select",
        text: "estadoNombre",
        value: "estadoId",
        clearable: true,
        items: [
          { estadoId: "PG_SOLICITADA", estadoNombre: "Solicitada" },
          { estadoId: "PG_COMPLETADA", estadoNombre: "Completada" },
          { estadoId: "PG_APROBADA", estadoNombre: "Aprobada" },
          { estadoId: "PG_CANCELADA", estadoNombre: "Cancelada" },
        ],
      },
    ],
    pagos: [],
    total_pagado: "",

    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 20,
    searchNumber: "",
    searchFechaInv: "",
    searchEstado: "",
    searchCliente: "",
    cuentasBancarias: [],
    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",
    existe_pago_cheque: false,
  }),
  watch: {
    options: {
      handler() {
        this.cargarLista(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    ...mapGetters("access", ["btnAbrirCaja"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista(filtrar) {
      if (filtrar == true) {
        this.currentPage = 1;
      }

      this.setLoadingTable(true);
      this.setUrl("payment-group");
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
          paymentGroupId: this.filters[0].v_model,
          statusId: this.filters[4].v_model,
          paymentGroupTypeId: this.filters[2].v_model,
          paymentGroupName: this.filters[3].v_model,
          fecha: this.filters[1].v_model,
        },
      })
        .then((res) => {
          this.lista = res.data._embedded.payment_group;
          this.pageCount = res.data.page_count;
          this.setLoadingTable(false);
        })
        .then(() => {});
    },

    handlePageChange(value) {
      //this.currentPage = value;
      this.cargarLista(false);
    },
  },

  mounted() {
    this.setTitleToolbar("ORDENES DE PAGO");
    //this.geEstablishment()
    //this.getFormasPago();
    //this.cargarCuentasBancarias();
  },
};
</script>
