<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="py-2 px-2">
      <v-form ref="formOrdenPago">
        <v-toolbar small color="white" flat>
          <v-toolbar-title>ORDEN DE PAGO</v-toolbar-title>
          <v-divider class="mx-2 " inset vertical> </v-divider>

          <v-btn v-if="datosOrden.estado_id == 'PG_SOLICITADA' && puedoAprobarOrdenPago" color="blue" small
            class="ma-2 white--text" @click="aprobarOrden">
            <v-icon left>mdi-check</v-icon>
            Aprobar Orden
          </v-btn>

          <v-btn v-if="datosOrden.estado_id == 'PG_SOLICITADA' && puedoAprobarOrdenPago" color="blue" small
            class="ma-2 white--text" @click="anularOrden">
            <v-icon left>mdi-check</v-icon>
            Anular Orden
          </v-btn>

          <v-btn v-if="datosOrden.estado_id == 'PG_APROBADA' && puedoProcesarOrdenPago" @click="dialogProcesar = true"
            color="primary" small class="ma-2 white--text">
            <v-icon left>mdi-cogs</v-icon>
            Procesar pagos
          </v-btn>

          <v-btn @click="dialogEstados = true" color="blue" small class="ma-2 white--text">
            <v-icon left>mdi-table-check</v-icon>
            Historial estados
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="py-2 px-4 mx-0 my-0">
          <v-col md="6" class=" py-0">
            <v-text-field label="Codigo" :readonly="soloLectura" v-model="datosOrden.payment_group_id"></v-text-field>
          </v-col>
          <v-col md="6" class="py-0">
            <v-text-field label="Fecha" :readonly="soloLectura" v-model="datosOrden.fecha"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pb-1 px-4 mx-0 my-0">
          <v-col md="6" class="py-0"><v-text-field :readonly="soloLectura" label="Nombre"
              v-model="datosOrden.payment_group_name"></v-text-field>
          </v-col>
          <v-col md="6" class="py-0"><v-text-field :readonly="soloLectura" label="Estado"
              v-model="datosOrden.estado"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pb-1 px-4 mx-0 my-0">
          <v-col md="6" class="py-0">
            <v-select :rules="[reqRule]" label="Cuenta bancaria" :readonly="soloLectura" :items="cuentasBancarias"
              item-text="fin_account_name" item-value="fin_account_id" v-model="datosOrden.fin_account_id">
            </v-select>
          </v-col>
          <v-col md="3" class="py-0">
            <v-text-field :readonly="soloLectura" label="Total Solicitado"
              v-model="datosOrden.total_solicitado"></v-text-field>
          </v-col>
          <v-col md="3" class="py-0">
            <v-text-field :readonly="soloLectura" label="Total pagado" v-model="datosOrden.total_pagado"></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :options.sync="options" :headers="pgmHeader" :items="pgMembers" :items-per-page="itemsPerPage"
          hide-default-footer class="elevation-1" show-expand item-key="party_id" :single-expand="false"
          :expanded.sync="pgInvoices">
          
          <template v-slot:item.payment_id="{item}">                 
            <v-btn small text :to="`/ver-pago-emitido/${item.payment_id}`">{{item.payment_id}}</v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" class="my-2">
                  <v-alert type="success" dense class="mb-0 py-1">
                    Documentos
                  </v-alert>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Tipo de documento</th>
                          <th class="text-center">Numero de documento</th>
                          <th class="text-center">Fecha</th>
                          <th class="text-center">Total</th>
                          <th class="text-center">Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="doc in item.documentos" :key="doc.invoice_id">
                          <td class="text-center">

                            <div v-if="doc.tipo_inv">
                              {{ doc.tipo_inv }}
                            </div>
                            <div v-if="doc.tipo_inv == null">
                              {{ "PAGO A FAVOR" }}
                            </div>
                          </td>
                          <td class="text-center">
                            <div v-if="doc.tipo_inv">
                              <v-btn small text v-if="doc.parent_type_id == 'SALES_INVOICE'"
                                :to="`/factura-venta/${doc.invoice_id}`" target="_blank">
                                {{ doc.invoice_number }}
                              </v-btn>
                            </div>
                            <div v-if="doc.tipo_inv">
                              <v-btn small text v-if="doc.parent_type_id == 'PURCHASE_INVOICE'"
                                :to="`/ver-factura-compra/${doc.invoice_id}`" target="_blank">
                                {{ doc.invoice_number }}
                              </v-btn>
                            </div>
                            <div v-if="doc.tipo_inv == null">

                              <v-btn small text v-if="doc.payment_id != ''" :to="`/ver-pago/${doc.payment_id}`"
                                target="_blank">
                              
                                {{ doc.payment_id }}
                              </v-btn>

                            </div>
                          </td>
                          <td class="text-center">
                            {{ doc.invoice_date }} {{ doc.effective_date }}
                          </td>
                          <td class="text-center">
                            <span v-if="doc.invoice_id != null">{{
                              parseFloat(doc.sub_total_imp1) +
                              parseFloat(doc.sub_total_imp2) +
                              parseFloat(doc.total_iva)
                            }}</span>
                            <span v-if="doc.invoice_id == null">
                              {{ doc.amount }}
                            </span>
                          </td>
                          <td class="text-center">{{ doc.saldo }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-form>
    </v-card>

    <!-- DIALOGO PARA PROCESAR ORDEN DE PAGO-->
    <v-dialog v-model="dialogProcesar" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Procesar orden de pago</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formProcesar">
              <v-row>
                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field :rules="[reqRule]" label="Referencia" v-model="paymentRefNum">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0"> </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field type="date" :rules="[reqRule]" label="Fecha" v-model="fechaPago">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0"> </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" text @click="dialogProcesar = false">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid small class="white--text" @click="procesarOrden">
            <v-icon>mdi-cogs</v-icon>
            Procesar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin dialog procesar -->
    <!-- DIALOGO PARA PROCESAR ORDEN DE PAGO-->
    <v-dialog v-model="dialogEstados" max-width="450px">
      <v-card>
        <v-toolbar dark color="blue" flat height="40">
          <v-toolbar-title>
            Historial de estados
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn small text @click="dialogEstados = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>


        <v-data-table :options.sync="options" :headers="pgEstadosHeader" :items="pgEstados" hide-default-footer
          class="elevation-1">
          <template v-slot:item.fecha="{ item }">
            {{ item.fecha.substring(0, 16) }}
          </template>
        </v-data-table>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" text @click="dialogEstados = false">
            <v-icon>mdi-cancel</v-icon>
            Cerrar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "OrdenpagoForm",
  components: {},
  data: function () {
    return {
      options: {},

      datosOrden: {
        payment_group_id: "",
        payment_group_type_id: "",
        tipo: "",
        fecha: "",
        estado_id: "",
        payment_group_name: "",
        estado: "",
        fin_account_id: "",
        total_solicitado: 0,
        total_pagado: 0,
        fin_account_name: "",
        fin_account_code: "",
      },
      pgmHeader: [
        { text: "Opciones", value: "opcion", align: "center", width: 50 },
        {
          text: "Proveedor / cliente",
          value: "prov_nombre",
          align: "start",
        },
        { text: "Fecha", value: "effective_date", align: "start" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Pago", value: "payment_id", align: "start" },
        { text: "Referencia", value: "payment_ref_num", align: "start" },
      ],
      pgMembers: [],
      pgInvoices: [],
      cuentasBancarias: [],
      pageCount: 1,
      itemsPerPage: 50,
      currentPage: 1,
      overlay: false,
      dialogProcesar: false,
      dialogEstados: false,
      pgEstados: [],
      pgEstadosHeader: [
        { text: "Fecha", value: "fecha", align: "start" },
        { text: "Estado", value: "estado", align: "start" },
        { text: "Usuario", value: "creado_por", align: "start" },
      ],
      paymentRefNum: "",
      fechaPago: "",
      reqRule: (v) => !!v || "El campo es requerido",
    };
  },

  props: {},
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    ...mapGetters("access", [
      "puedoAprobarOrdenPago",
      "puedoSolicitarOrdenPago",
      "puedoProcesarOrdenPago",
    ]),
    soloLectura() {
      if (this.datosOrden.estado_id != "PG_SOLICITADA") {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarOrden() {
      this.setLoadingTable(true);
      this.setUrl("payment-group/" + this.$route.params.paymentGroupId);
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.datosOrdenl = res.data.pg;
          this.pageCount = 1;
          this.datosOrden = res.data.pg;
          this.pgMembers = res.data.pgm;
          this.pgInvoices = res.data.pgi;
          this.pgEstados = res.data.estados;
          this.setLoadingTable(false);
        })
        .then(() => { });
    },
    cargarCuentasBancarias() {
      this.setLoadingTable(true);

      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
        this.setLoadingTable(false);
      });
    },
    solicitarAprobacion() { },
    aprobarOrden() {
      if (!this.$refs.formOrdenPago.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("payment-group/" + this.$route.params.paymentGroupId);

      this.requestApi({
        method: "PUT",
        data: {
          accion: "aprobar",
          fin_account_id: this.datosOrden.fin_account_id,
        },
      })
        .then((res) => {
          this.setLoadingTable(false);
          document.location.reload();
        })
        .then(() => { });
    },

    procesarOrden() {
      if (!this.$refs.formOrdenPago.validate()) {
        return false;
      }
      if (!this.$refs.formProcesar.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("payment-group/" + this.$route.params.paymentGroupId);

      this.requestApi({
        method: "PUT",
        data: {
          accion: "procesar",
          payment_ref_num: this.paymentRefNum,
          fecha: this.fechaPago
        },
      })
        .then((res) => {
          this.setLoadingTable(false);
          document.location.reload();
        })
        .then(() => { });
    },

    anularOrden() {
      // if (!this.$refs.formOrdenPago.validate()) {
      //   return false;
      // }
      // if (!this.$refs.formProcesar.validate()) {
      //   return false;
      // }
      this.setLoadingTable(true);
      this.setUrl("payment-group/" + this.$route.params.paymentGroupId);

      this.requestApi({
        method: "PUT",
        data: {
          accion: "anular"
        },
      })
        .then((res) => {
          this.setLoadingTable(false);
          document.location.reload();
        })
        .then(() => { });
    }
  },
  mounted() {
    this.setTitleToolbar("ORDEN PAGO");
    this.cargarOrden();
    this.cargarCuentasBancarias();
  },
};
</script>
